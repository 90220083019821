export const SELL = 'Продать'
export const BUY = 'Купить'

export const EUR = 'EUR'
export const RUB = 'RUB'
export const USD = 'USD'
export const HUF = 'HUF'
export const TRY = 'TRY'

export const CURRENCIES = [
    EUR,
    RUB,
    USD,
    HUF,
    TRY
]